<!-- Registrasi ulang per formulir -->
<template>
    <div>
        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- Breadcrumb -->
                <div class="position-fixed w-100" style="top:0;z-index:99;transform:translateX(-1px);">
                    <div class="d-flex mb-4 pt-3 pb-3 border-bottom align-items-center bg-white">
                        <router-link :to="{ name: 'admin.dashboard' }">Beranda</router-link>
                        <span class="text-muted mx-2">|</span>
                        <div class="text-muted">Registrasi Ulang</div>
                        <span class="text-muted mx-2"><span class="fe fe-arrow-right-circle mr-1 text-sm"></span></span>
                        <div class="text-muted">List Konfirmasi</div>
                    </div>
                </div>
                <!-- -->
                <div class="d-block mb-4 pt-5 mt-3">
                    <div class="d-flex align-items-center">
                        <div class="mr-3">
                            <img src="https://scolacdn.com/frontend/argonne-img/user-icon-teacher.png" width="38">
                        </div>
                        <div>
                            <h1 class="header-title">
                                Konfirmasi Registrasi Ulang
                            </h1>
                            <div class="d-block">
                                Menu ini digunakan untuk mengelola konfirmasi registrasi ulang.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-inline-block mb-3 p-2 px-3 text-sm border rounded">
                    <span class="fe fe-file mr-2 text-muted"></span>{{ dataRegistrasiUlang.total_data }} data tersedia
                </div>
                <!-- CONTENT -->
                <div class="card">
                    <!-- Filter -->
                    <div class="d-block p-4 border-bottom">
                        <div class="row">
                            <div class="col-3 pr-md-0">
                                <select class="form-control shadow-sm text-sm form-control-rounded custom-select" v-model="periode_id" v-on:change="getData(true)">
                                    <option value="0">Lihat Semua Periode</option>
                                    <option v-for="row in dataMasterPeriode" :key="row.id" :value="row.id">{{row.kode_periode}} - {{row.nama_periode}} {{ row.aktif == 1 ? '(aktif)' : '' }}</option>
                                </select>
                            </div>
                            <div class="col-4 pr-md-0">
                                <select class="form-control shadow-sm text-sm form-control-rounded custom-select" v-model="gelombang_id" v-on:change="getData(true)">
                                    <option value="">Lihat Semua Gelombang</option>
                                    <option v-for="row in dataMasterGelombang" :key="row.id" :value="row.id">{{row.kode_gelombang}} - {{row.nama_gelombang}}</option>
                                </select>
                            </div>
                            <div class="col-5">
                                <select class="form-control shadow-sm text-sm form-control-rounded custom-select" v-model="formulir_id" v-on:change="getData(true)">
                                    <option value="">Lihat Semua Formulir</option>
                                    <option v-for="row in dataMasterFormulir" :key="row.id" :value="row.id">{{row.sub_unit_title}} {{row.nama_formulir}}</option>
                                </select>
                            </div>
                            <div class="col-md-12 mt-3">
                                <!-- Search -->
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <i class="fe fe-search text-sm"></i>
                                        </span>
                                    </div>
                                    <input type="search" class="form-control text-sm search" v-model="keywords" v-on:keyup.enter="getData(true)" placeholder="Cari data disini...">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->
                    <div class="table-responsive">
                        <table class="table table-sm card-table table-hover table-striped">
                            <thead>
                                <tr>
                                    <th class="pr-0">No</th>
                                    <th>Formulir</th>
                                    <th class="pl-0">Unit</th>
                                    <th class="pl-0">Sub Unit</th>
                                    <th>Gelombang</th>
                                    <th>Periode</th>
                                    <th>Mendaftar</th>
                                    <th>Ket.</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody v-if="fetching">
                                <tr>
                                    <td colspan="8">
                                        <div class="alert alert-light m-0" role="alert">
                                            <div class="d-flex align-items-center">
                                                <div class="spinner-border spinner-border-sm mr-3" role="status"></div>
                                                <div>Sedang mengambil data..</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-if="!fetching" class="list">
                                <tr v-for="(register, index) in dataRegistrasiUlang" :key="index">
                                    <td class="text-center pr-0">
                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                    </td>
                                    <td>
                                        <div class="d-block font-weight-bold h3 mb-1">{{ register.nama_formulir }}</div>
                                        <div class="d-flex align-items-center flex-wrap">
                                            <span class="badge bg-primary-soft text-primary text-sm mr-3">Kode : {{ register.kode_formulir }}</span>
                                        </div>
                                    </td>
                                    <td class="pl-0">
                                        {{ register.unit_title }}
                                    </td>
                                    <td class="pl-0">
                                        {{ register.sub_unit_title }}
                                    </td>
                                    <td>
                                        {{ register.nama_gelombang }}
                                    </td>
                                    <td>
                                        {{ register.nama_periode }}
                                    </td>
                                    <td>
                                        {{ register.jumlah_terdaftar }} Siswa
                                    </td>
                                    <td>
                                        {{ register.keterangan }}
                                    </td>
                                    <td class="orders-status">
                                        <router-link :to="{ name: 'registrasi.ulang.performulir', params: { id: register.id }}" class="btn btn-primary btn-sm"><i class="fe fe-eye mr-2"></i>Lihat</router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer border-top-0">
                        <div style="float:right">
                            <!-- <ul class="pagination mb-0"></ul> -->
                            <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="changePage()"></v-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        components: {
            vPagination
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                dataRegistrasiUlang: [],
                fetching: true,
                dataMasterFormulir: [],
                dataMasterPeriode: [],
                dataMasterGelombang: [],
                pagination: {
                    currentPage: 1,
                    totalPages: 0,
                    limit: 5,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                periode_id: '',
                gelombang_id: '',
                formulir_id: ''
            }
        },
        created() {
            if (localStorage.getItem("page_re_registration") !== null) {
                this.pagination.currentPage = parseInt(localStorage.getItem("page_re_registration"));
            }
            this.getData();
            this.getDataPeriode();
        },
        methods: {
            // CHANGE PAGE PAGINATION
            changePage() {
                localStorage.setItem("page_re_registration", this.pagination.currentPage);
                this.getData();
            },
            // GET DATA REGISTER
            getData(reset = false) {
                if (reset === true) {
                    this.pagination.currentPage = 1;
                    this.pagination.totalPages = 0;
                    localStorage.setItem("page_re_registration", this.pagination.currentPage);
                }
                this.fetching = true;
                this.dataRegistrasiUlang = [];
                this.$http.get(this.baseUrl + 'admin/registrasi-ulang/data/per-formulir', {
                        params: {
                            page: this.pagination.currentPage,
                            keywords: this.keywords,
                            periode_id: this.periode_id,
                            gelombang_id: this.gelombang_id,
                            formulir_id: this.formulir_id,
                        }
                    })
                    .then((response) => {
                        this.getDataGelombang();
                        this.getDataFormulir();
                        this.fetching = false;
                        if (response.data.status) {
                            this.dataRegistrasiUlang = response.data.data;
                        }
                        this.pagination.totalPages = response.data.total_page;
                        this.dataRegistrasiUlang.total_data = response.data.total_data;
                    }).catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    })
            },
            // GET DATA PERIODE
            getDataPeriode() {
                this.$http.get(this.baseUrl + 'admin/master/periode')
                    .then((response) => {
                        this.dataMasterPeriode = response.data.data;
                        this.periode_id = response.data.aktif
                        this.getDataGelombang();
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            // GET DATA GELOMBANG
            getDataGelombang() {
                this.$http.get(this.baseUrl + 'admin/master/gelombang/aktif?periode_id='+this.periode_id)
                    .then((response) => {
                        this.dataMasterGelombang = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            // GET DATA FORMULIR
            getDataFormulir() {
                this.$http.get(this.baseUrl + 'admin/master/formulir/gelombang?gelombang_id='+this.gelombang_id+'&periode_id='+this.periode_id)
                    .then((response) => {
                        this.dataMasterFormulir = response.data.data;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            }
        }
    }
</script>